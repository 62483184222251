:root {
  --primary-color: rgb(9,28,48);
  --secondary-color: #181818;
  --header-button: #580000;
  --button: -webkit-linear-gradient(#e52d27,#b31217);
  --color-white: #feeeee;
  --color-shadow: #141414;
  --ruler: 16px;
  --header-color: #df4759;
  --page-background: #fff;
  --table-background-color: #ff8080;
}

*{
  user-select: none;
  }

  .green {
    color: #66ff00;
  }

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Baloo';
  src: local('Baloo'), url(./assets/fonts/baloo/Baloo-Regular.ttf) format('truetype');
}

body {
  background: var(--page-background);
}

h1 {
  font-family: Baloo;
}

h2 {
  font-weight: bold;
  font-size: 2rem;
  color: var(--header-color) !important;
  text-transform: uppercase;
}

h2, h3, p {
  font-family: Montserrat;
}

p {
  font-size: 1.3rem;
}



/* LIST STYLING */
.listing {
    background: var(--table-background-color);
    border-color: none;
    border-radius: 5px;
    color: #fff;
    /* box-shadow: -3px -3px 7px #ffffffb2, 3px 3px 5px rgba(94, 104, 121, 0.945); */
}
.inset-listing {
  background: #222;
  outline: 0;
  color: #fff;
  border-radius: 5px;
  box-shadow: inset -3px -3px 7px #ffffffb0, inset 3px 3px 5px rgba(94, 104, 121, 0.692);
}

/* ---------------------------------------------------
   SIDEBAR STYLE
----------------------------------------------------- */
#App {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  z-index: 3;
}

#header {
  position: fixed !important;
  width: 100%;
  height: 100px;
  background: var(--secondary-color);
  border: none;
  z-index: 5;
}

#header button{
  margin-top: 4vh;
  margin-right: 2vh;
  font-family: Montserrat;
}

#header img{
  padding: 7px;
  margin-top: 13px;
}

.bm-burger-bars {
  background: #fff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  /* background-image: linear-gradient(to bottom right,#ff0084, #33001b); */
  background: var(--secondary-color);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
a.menu-item{
  /* position: relative; */
  margin-top: 6vh;
  /* margin-right: 5rem; */
  padding: 0;
  text-align: left;
}

#menuImg{
   height: 17.5vh;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
}
#menuImg:focus{
  outline: none;
}
#menuImg h2{
  margin-top: 8vh;
  font-family: GreatVibes;
  font-weight: bold;
}
#span{
  padding: 12px
}
.logoTxt {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  align-content: center !important;
  margin-top: 30vh;
}
.logoTxt h1{
  font-family: GreatVibes;
  font-weight: bold;
  font-size: 2.6rem;
}
.logoTxt h5{
  font-family: south;
  font-size: 2.5rem;
}

p{
  font-family: montserrat;
}
/* -----------------------------------------------
  END OF SIDEBAR STYLE
--------------------------------------------------*/

/*------------------------------------------------
  START OF GALLERY
-------------------------------------------------*/
.gallery {
  background: #333;
  display: grid;
  grid-gap: 20px;
  padding: 2rem;
  height: 100%;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  box-sizing: border-box;
  line-height: 1.4;
}

.gallery img {
  border-radius: 2px;
  width: 100%;
}

.gallery p a {
  transition: transform .3s;
}

.gallery a:hover {
  transition: transform .3s;
  -ms-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.cob-gal:hover {
  opacity: 0.8;
}
/*------------------------------------------------
  END OF GALLERY
-------------------------------------------------*/

/*-----------------------------------------------
  START OF SKELETON LOADER
-------------------------------------------------*/
.skeleton-container {
  background: #181818;
  margin: 0 auto;
  max-width:930px;
}
@keyframes placeHolderShimmer {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: -468px 0
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: 468px 0
    }
}

.card-skeleton {
    width: 270px;
    height:300px;
    margin: 5px;
    position: relative;
     float: left;
    top: 0;
    left: 0;
    transition: all .3s ease-in-out;
    -webkit-backface-visibility: hidden;
    background: #fff;
    z-index: 10;
    padding: 15px;
    opacity: 1
}

.card-skeleton.hidden {
    transition: all .3s ease-in-out;
    opacity: 0;
    height: 0;
    padding: 0
}

.card-skeleton-img {
    width: 100%;
    height: 120px;
    background: #e6e6e6;
    display: block
}

.animated-background {
    will-change: transform;
    animation: placeHolderShimmer 1s linear infinite forwards;
    -webkit-backface-visibility: hidden;
    background: #e6e6e6;
    background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 800px 104px;
    height: 100%;
    position: relative
}

.skel-mask-container {
    position: relative
}

.skel-mask {
    background: #fff;
    position: absolute;
    z-index: 200
}

.skel-mask-1 {
    width: 100%;
    height: 15px;
    top: 0;
    left: 0
}

.skel-mask-2 {
    width: 25%;
    height: 10px;
    top: 15px;
    right: 0
}

.skel-mask-3 {
    top: 25px
}

.skel-mask-3,
.skel-mask-4 {
    width: 100%;
    height: 15px;
    right: 0
}

.skel-mask-4 {
    top: 50px
}

.skel-mask-5 {
    width: 10%;
    height: 30px;
    top: 65px;
    right: 30%
}

.skel-mask-6 {
    width: 100%;
    height: 15px;
    top: 95px;
    right: 0
}

/*------------------------------------------------
  END OF SKELETON LOADER
-------------------------------------------------*/

/*------------------------------------------------
  START OF NURSERY STYLE
-------------------------------------------------*/

Table {
  /* box-shadow: inset -3px -3px 7px #ffffffb0, inset 3px 3px 5px rgba(94, 104, 121, 0.692); */
  font-size: 1.2rem;
  background: var(--table-background-color);
}

Table:hover{
  cursor: pointer;
}

Table td {
  color: #fff;
  font-weight: bold;
}


/*------------------------------------------------
  END OF NURSERY STYLE
-------------------------------------------------*/

/* ---------------------------------------
HEADER
------------------------------------------ */
a {
	color: #fff;
	text-decoration: none;
}


img {
	width: 100%;
}

#enroll-small {
  display: none;
}

.showcase {
  margin-top: 11vh;
	width: 100%;
	height: 93vh;
	position: relative;
	background: url('./assets/images/kid2.jpeg') no-repeat center center/cover;
}

.showcase::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.6);
	box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
}

.showcase-top {
	position: relative;
	z-index: 2;
	height: 90px;
}

.showcase-top img {
	width: 170px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-left: 0;
}

.showcase-top a {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(-50%, -50%);
}

.showcase-content {
	position: relative;
	z-index: 2;
	width: 65%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 9rem;
}

.showcase-content h1 {
	font-weight: 700;
	font-size: 4.0rem;
  line-height: 1.1;
  color: #fff;
	margin: 0 0 2rem;
}

.showcase-content p {
	text-transform: uppercase;
	color: #fff;
	font-weight: 400;
	font-size: 1.9rem;
	line-height: 1.25;
	margin: 0 0 2rem;
}
/* Buttons */
.btn {
	display: inline-block;
	background: var(--header-button);
  color: #fff;
  box-shadow: -5px -5px 20px var(--secondary-color),  5px 5px 20px var(--color-shadow);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
	padding: 0.4rem 1.3rem;
	font-size: 1rem;
	text-align: center;
	border: none;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: opacity 0.2s ease-in;
	outline: none;
	border-radius: 2px;
}
.btn-alt {
	display: inline-block;
	background: var(--header-button);
  color: #fff;
  text-transform: capitalize;
  box-shadow: -5px -5px 20px var(--color-white),  5px 5px 20px var(--color-shadow);
  transition: all 0.2s ease-in-out;
  font-weight: 600;
	box-sizing: border-box;
	font-size: 1.1rem;
	text-align: center;
	margin-right: 0.5rem;
	outline: none;
  border-radius: 40px;
	padding: 1rem 1.6rem;
}

.btn-alt:hover {
  color: var(--color-white);
  box-shadow: -2px -2px 5px var(--secondary-color), 2px 2px 5px var(--color-shadow);
}

.btn:hover {
  color: var(--color-white);
  box-shadow: -2px -2px 5px var(--secondary-color), 2px 2px 5px var(--color-shadow);
}

.btn-rounded {
	border-radius: 5px;
}

.btn-xl {
	font-size: 2rem;
	padding: 1.5rem 2.1rem;
	text-transform: uppercase;
}


.btn-lg {
	font-size: 1rem;
	padding: 0.8rem 1.3rem;
	text-transform: uppercase;
}

.btn-icon {
	margin-left: 1rem;
}

@media (max-width: 960px) {
  .showcase {
  height: 83vh;
}

.hide-sm {
  display: none;
}

.showcase-top img {
  top: 30%;
  left: 5%;
  transform: translate(0);
}

.showcase-content h1 {
  font-size: 3.7rem;
  line-height: 1;
}

.showcase-content p {
  font-size: 1.5rem;
}

.btn-xl {
  font-size: 1.5rem;
  padding: 1.4rem 2rem;
  text-transform: uppercase;
}

.text-xl {
  font-size: 1.5rem;
}

.text-lg {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.text-md {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
}

@media (max-width: 700px) {
.showcase::after {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 80px 80px 150px #000000, inset -80px -80px 150px #000000;
}

.showcase-content h1 {
  font-size: 2.0rem;
  line-height: 1;
  margin-top: 1rem;
}

.showcase-content p {
  font-size: 1rem;
}

.showcase-top img {
	width: 80px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-left: 0;
}

#enroll-big {
  display: none;
}

#enroll-small {
  display: inline-block;
}

.btn-xl {
  font-size: 0.9rem;
  padding: 1.2rem 1.6rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
}

@media(max-height: 600px) {
.showcase-content {
margin-top: 3rem;
}

.showcase-top img {
	width: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-left: 0;
}
}

/*-----------------------------------------------
 START OF PROPRIETOR'S WELCOME MESSAGE
 ------------------------------------------------*/
 .author-box-intro {
	margin-top: 15px;
}
.author-box h4{
  font-weight: bold;
}
a.author-social-link.small {
    margin-left: 5px;
}
.left-margin{
  margin-left: 18rem;
}
/* Small Devices, Tablets */
@media only screen and (max-width : 768px){
.center-block {
	margin: 0 auto;
}
.author-box {
  text-align: center;
  font-family: Montserrat;
}
.author-social-links {
	text-align: center;
	display: block;
	margin-top: 10.5px;
}
#img-lg{
   display: none;
}
}

 /*----------------------------------------------
 END OF PROPRIETOR'S WELCOME MESSAGE
 ------------------------------------------------*/

/*------------------------------------------------
 START OF TOUR DESIGN
 ------------------------------------------------*/
 .o-video {
  width: 100%;
  height: 0;
  position: relative;
  margin-bottom: 35rem;
  /* padding-top: 36.25%;  */
}
.o-video > video {
  object-fit: cover;
  width: 100%;
  height: 60vh;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

 /*----------------------------------------------
  END OF TOUR DESIGN
 -----------------------------------------------*/

 /*---------------------------------------------
  START OF VISION AND MISSION CARD
  ---------------------------------------------*/

.fancy-cards{
  text-align: center;
  margin: 25px 18px;
}
.fancy-cards .fancy-card{
  display: inline-flex;
  justify-content: center;
  align-content: center;
  margin: 20px;
  position: relative;
  top: 40px;
  left: 20px;
  box-sizing: border-box;
}

.fancy-card .top,
.fancy-card .middle,
.fancy-card .bottom{
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  width: 250px;
  height: 250px;
  transition: transform 300ms linear-out;
}
.fancy-card .top{
  z-index: 3;
  transform: scale(1.0);
  transition: transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
  /* background-image: url('https://static-1.gumroad.com/res/gumroad/assets/collections/food_and_cooking_thumb-34fb9ef316a7b01177529839891c3a03.jpg'); */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 1px 3px rgba(25,25,25,0.30);
}
.fancy-card .middle{
  position: absolute;
  background: #aaa;
  top: 0px;
  z-index: 2;
  transform: rotate(0deg);
  transition: transform 250ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.fancy-card .bottom{
  position: absolute;
  background: #ccc;
  top: 0px;
  z-index: 1;
  transform: rotate(0deg);
  transition: transform 250ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.fancy-card .caption{
  overflow: hidden;
  background: rgba(255,255,255,0.75);
  padding: 15px 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.fancy-card .caption .title{
  color: #222;
  margin: 0px 0px 15px 0px;
  font-size: 1.4rem;
}

.fancy-card .caption .button{
  display: inline-block;
  color: #333;
  text-decoration: none;
  border: solid 1px #555;
  padding: 7px 13px;
  background-color: transparent;
  transition: all 300ms ease-in;
}


/*hovering*/
.fancy-card:hover .top{
  transform: scale(1.05);
}
.fancy-card:hover .middle{
 transform: rotate(-7deg);
 box-shadow: 1px 1px 2px rgba(74, 74, 74, 0.35);
}
.fancy-card:hover .bottom{
  transform: rotate(7deg);
  box-shadow: 1px 1px 2px rgba(113, 113, 113, 0.35);
}

.fancy-card:hover .button{
  background: rgba(0,0,0,0.8);
  color: #fff;
  border: 0px;
}
  /*--------------------------------------------
  END OF VISION AND MISSION CARD
   ---------------------------------------------*/

/* -----------------------------------------------
 PARALLAX DISPLAY
-------------------------------------------------*/
.bannerItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top:10px;
  width:100%;
  margin:auto;
  margin-top:55px;
  max-height:80vh !important;
}

.banner-header{
  margin: 0;
  color: #fff;
  padding-top:0px;
  font-size: 42px;
  font-family:montserrat;
  padding-left: 10px;
  padding-right: 10px;
  padding-top:-190px;
  font-weight:100;
}

.banner-text{
  margin: 0;
  color: #fff;
  margin-top:0px;
  padding-bottom: 0px;
  font-family: montserrat;
  font-weight: 100;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.bannerItem{
  background: #333;
  text-align: center;
  height: 60vh !important;
  border-radius: 6px; /* rounded corners */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%,rgba(255, 175, 123, 0.5) 100%), url("./assets/images/gallery.jpg");
  size: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  max-height: 0%;
  background-size:100%;
  }



.banner-example{
margin: 20px;
}


/* button CSS */
.btnp.btnp-outline{
margin-top:10px;
background-color: var(--header-button);
color: #fff;
border: #fff;
border-color: #fff;
padding-left:90px;
padding-right:90px;
font-family:montserrat;
font-weight:300;
margin-bottom:40px;
border-radius:0px;
}
.btnp.btnp-outline:hover{
background-color:black;
border-color:black;
color: #fff;
}
.btnp.btnp-outline.a{
background-color:black;
border-color:black;
color: #fff;
}

.btnp.btnp-outline:active{
font-weight:300;
font-size:20px;
color: #fff;
}
.banner-example .banner-header{
  font-weight: bold;
}

/*-----------------------------------------------
START OF CONTACT US
------------------------------------------------*/
#wrapper {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background: #181818;
  color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
}

#left {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 120vh;
  position: relative;
  top: 1vh;
}

#right {
  flex: 1;
  align-items: center;
  justify-content: center;
}

/* Sign In */
#signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-bottom: 1rem;
}

#signin form {
  width: 80%;
  padding-bottom: 3rem;
}

#signin .logo {
  margin-bottom: 8vh;
}

#signin .logo img {
  width: 180px;
}

#signin label {
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 500;
  color: #fff;
  font-weight: bold;
}

#signin .text-input {
  margin-bottom: 1.3rem;
  width: 100%;
  border-radius: 2px;
  background: #181818;
  border: 1px solid #555;
  color: #ccc;
  padding: 0.5rem 1rem;
  line-height: 1.3rem;
}

#signin .text-input:focus{
  outline: none;
}

#signin .primary-btn {
  width: 100%;
}

#signin .secondary-btn,
.or,
.links {
  width: 70%;
}

#signin .links a, a {
  color: #fff;
  text-decoration: none;
  text-align: center;
}

#signin .links h3{
  margin-top: -3rem;
}

#signin .or {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.2rem;
  align-items: center;
}

#signin .or .bar {
  flex: auto;
  border: none;
  height: 1px;
  background: #aaa;
}

#signin .or span {
  color: #ccc;
  padding: 0 0.8rem;
}

/* Showcase */
#showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('assets/images/sunflower.png') no-repeat center center / cover;
  height: 120vh;
}

#showcase::after{
  height: 100vh;
  z-index: 3;
	background: rgba(0, 0, 0, 0.6);
	box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
}

#showcase .showcase-text {
  font-size: 3rem;
  width: 100%;
  color: #fff;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

#showcase .showcase-text h3 {
  font-size: 2.5rem;
  width: 100%;
  color: #f96816;
  margin-bottom: 1.5rem;
}

#showcase .secondary-btn {
  width: 60%;
  margin: auto;
}

/* Footer */
#main-footer {
  color: #ccc;
  text-align: center;
  font-size: 0.8rem;
  max-width: 80%;
}

#main-footer a {
  color: #fff;
  font-weight: 300;
  text-decoration: underline;
}

/* Button */
.primary-btn {
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  display: block;
  border: 0;
  font-size: 1.2rem;
  border-radius: 2px;
  font-weight: 500;
  background: var(--header-button);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;
  transition: all 0.5s;
}

.primary-btn:hover {
 opacity: 0.6;
}

.secondary-btn {
  padding: 0.7rem 1rem;
  height: 2.7rem;
  display: block;
  border: 1px solid #f4f4f4;
  border-radius: 2px;
  font-weight: 500;
  background: none;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
}

.secondary-btn:hover {
  border-color: #ff7b39;
  color: #ff7b39;
}

/* Media Queries */
@media (min-width: 1200px) {
  #left {
    flex: 4;
  }

  #right {
    flex: 6;
  }
}

@media (max-width: 768px) {
  body {
    overflow: auto;
  }

  #right {
    display: none;
  }

  #left {
    justify-content: start;
    margin-top: 4vh;
  }

  #signin .logo {
    margin-bottom: 2vh;
  }

  #signin .text-input {
    margin-bottom: 0.7rem;
  }

  #main-footer {
    padding-top: 1rem;
  }
}

/*-----------------------------------------------
END OF CONTACT US
------------------------------------------------*/


/* ------------------------------------------------
 START OF FOOTER
--------------------------------------------------- */
.new_footer_area {
  background: var(--page-background);
}

.new_footer_top {
  padding: 120px 0px 270px;
  position: relative;
    overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}
.btn_get_two:hover {
  background: transparent;
  color: var(--header-button);
}
.btn_get:hover {
  color: #fff;
  background: var(--header-button);
  border-color: var(--header-button);
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  /* color: var(--header-button); */
  font-weight: bold;
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: "\e741";
}
.ti-twitter-alt:before {
  content: "\e74b";
}
.ti-vimeo-alt:before {
  content: "\e74a";
}
.ti-pinterest:before {
  content: "\e731";
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: var(--header-button);
  border-color: #5e2ced;
  font-family: Baloo;
  transition: 0.5s;
  color: #fff;
}

.btn_get_two:hover {
 opacity: 0.9;
  color: #fff851;
}

.new_footer_top .f_social_icon a:hover {
  background: var(--header-color);
  font-weight: bold;
color:white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
h1, h2, h3, h4, h5, h6 {
  color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}


.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url("./assets/images/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("./assets/images/volks.gif") no-repeat center center;
  width: 330px;
  height: 105px;
  background-size:100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("./assets/images/cyclist.gif") no-repeat center center;
  width: 88px;
  height: 100px;
  background-size:100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}



@-moz-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@-webkit-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

/* -------------------------------------------------
  END OF FOOTER
-------------------------------------------------- */

@media only screen and (max-width: 568px) {
  .fancy-cards{
     position: relative;
     right: -10rem;
  }
}
@media only screen and (max-width: 600px) {
  .fancy-cards{
     position: relative;
     right: 1rem;
  }
  .showcase-content h1 {
    margin-top: -2.9rem;
  }
  .strive {
    margin-top: 1.618rem;
  }
  .boot-sm {
    padding-left: 15px;
  }
  .welcome {
    margin-left: 0;
  }
  .welcome p {
    margin: 0;
  }
  h2 {
    font-size: 1.5rem;
  }
}

/* MEDIA QUERIES */

@media(max-width: 900px) {
  .gallery {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }

}

@media(max-width: 700px) {

  .gallery {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}

@media(max-width: 500px) {

  .gallery {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .gallery a:hover {
    transition: transform .3s;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1.2);
  }
}

/* ==============================
 START OF TESTIMONIAL CONTAINER
================================*/
/* .columns {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 5px 0;
  width: 100%;
}
figure.snip1390 {
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 5px;
  min-width: 261px;
  max-width: 360px;
  width: 100%;
  color: #000000;
  text-align: center;
  font-size: 16px;
  background-color: #2C3447;
 
  padding: 30px;
   
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip1390 *,
figure.snip1390 *:before,
figure.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
figure.snip1390 figcaption {
  width: 100%;
}
figure.snip1390 h2,
figure.snip1390 h4,
figure.snip1390 blockquote {
  margin: 0;
}
figure.snip1390 h2,
figure.snip1390 h4 {
  font-weight: 300;
}
figure.snip1390 h2 {
  color: #ffffff;
}
figure.snip1390 h4 {
  color: #a6a6a6;
}
figure.snip1390 blockquote {
  font-size: 1em;
  padding: 45px 20px 40px 50px;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
  text-align: left;
  position: relative;
}
figure.snip1390 blockquote:before {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 30px;
  opacity: 0.25;
  font-style: normal;
  top: 0px;
  left: 20px;
}
figure.snip1390 .profile {
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  border: solid 5px var(--primaryColor);
} */
.testimonial-container {
  display: flex;
  overflow: auto ; /* Enables horizontal scrolling */
  gap: 20px; /* Space between testimonial cards */
  padding: 20px;
  scroll-behavior: smooth; /* Adds smooth scrolling */
}

.testimonial-card {
  flex: 0 0 auto; /* Prevent cards from shrinking */
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.testimonial-message {
  font-style: italic;
  color: #555;
  margin-bottom: 10px;
}

.testimonial-name {
  font-size: 1.2em;
  color: #333;
  margin: 10px 0 5px;
}

.testimonial-role {
  color: #777;
  font-size: 0.9em;
}

.testimonial-container::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.testimonial-container::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.testimonial-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


/* ==============================
 END OF TESTIMONIAL CONTAINER
================================*/
